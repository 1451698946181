.main {
	width: 100%;
	margin: auto;
	overflow: hidden;
	padding-top: 70px;
	@include breakpoint($s){
		padding-top: 104px;
	}
	@include breakpoint($l){
		height: 100vh;
		padding-top: 0;
	}
	
	
	&.main--login {
		@include breakpoint($l){
			background: url(../images/login/img.jpg) no-repeat;
			background-size: cover;
			background-position: 25% 0;
		}
		//ipad pro portrait
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			background: none;
		}
		@include breakpoint($xl){
			background-position: 0 0;
		}
	}
	&.main--confirmation {
		background: url(../images/confirmation/img-mobile.jpg) no-repeat;
		@include breakpoint($s){
			background: url(../images/confirmation/img-tablet.jpg) no-repeat;
			background-size: cover
		}
		@include breakpoint($l){
			background: url(../images/confirmation/img-success.jpg) no-repeat;
			background-size: cover
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			background: url(../images/confirmation/img-tablet.jpg) no-repeat;
			background-size: cover
		}	
		&.failure {
			background: url(../images/confirmation/img-failure-mobile.jpg) no-repeat;
			@include breakpoint($s){
				background: url(../images/confirmation/img-failure-tablet.jpg) no-repeat;
				background-size: cover
			}
			@include breakpoint($l){
				background: url(../images/confirmation/img-failure.jpg) no-repeat;
				background-size: cover
			}
			@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
				background: url(../images/confirmation/img-failure-tablet.jpg) no-repeat;
				background-size: cover
			}	
		}
		&.success {
			background: url(../images/confirmation/img-success-mobile.jpg) no-repeat;
			@include breakpoint($s){
				background: url(../images/confirmation/img-success-tablet.jpg) no-repeat;
				background-size: cover
			}
			@include breakpoint($l){
				background: url(../images/confirmation/img-success.jpg) no-repeat;
				background-size: cover
			}
			@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
				background: url(../images/confirmation/img-success-tablet.jpg) no-repeat;
				background-size: cover
			}	
		}
		&, 
		&.success,
		&.failure {
			background-size: 100% auto;
			@include breakpoint($l){
				height: 100vh;
				background-size: auto 100%;
			}
			@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
				background-size: 100% auto;
				height: auto;
			}	
		}
	}
	&.main--forgotPassword {
		@include breakpoint($l,min, landscape){
			background: url(../images/forgotPassword/img-tablet.jpg) no-repeat;
			background-size: auto 100vh;
			height: 100vh;
			background-position: -5% 0;
		}
		@media only screen and (min-device-width: 1024px) and (max-device-height: 768px){
			background-position: -18% 0;
		}
		@include breakpoint($xl){
			background: url(../images/forgotPassword/img.jpg) no-repeat;
			background-size: auto 100vh;
			background-position: -21% 0;
		}
		//ipad pro portrait
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			background: none;
		}
		//ipad pro landscape
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2){
			
			background: url(../images/forgotPassword/img-tablet.jpg) no-repeat;
			background-size: auto 100vh;
			background-position: -5% 0;
		}
		//mid desktop
		@media only screen and (min-device-width: 1366px) and (max-device-height: 960px){
			background-position: 0 0;
			background: url(../images/forgotPassword/img.jpg) no-repeat;
			background-size: auto 100vh;
		}
		@media only screen and (min-device-width: 1280px) and (min-device-height: 800px){
			background: url(../images/forgotPassword/img.jpg) no-repeat;
			background-position: 0 0;
			background-size: auto 100vh;
		}
		@include breakpoint($xxl){
			background: url(../images/forgotPassword/img.jpg) no-repeat;
			background-position: 0 0;
			background-size: auto 100vh;
		}
		@include breakpoint($xxxl){
			background-position: 0 0;
		}

	}
	
}

.content{
	@include clearfix;
	padding: 40px 0 60px;
	position: relative;
	margin: auto;
	z-index: 20;
	@include breakpoint($l){
		width: calc(100% - 160px);
		padding: 0;
		margin-bottom: 60px;
	}
	@include breakpoint($xl){
		width: 80%;
		padding: 32px 0;
	}
	@include breakpoint($xxl){
		margin-bottom: 0;
	}
	@include breakpoint($xxxl){
		width: 75%;
		padding: 32px 0;
	}
	.main--login &,
	.main--forgotPassword & {
		@include breakpoint($s){
			padding-top: 120px;
		}
		@include breakpoint($l){
			padding-top: 0;
		}
		@include breakpoint($l,min, portrait){
			width: 100%;
			padding: 32px 0;
		}
	}
	.main--confirmation & {
		@include breakpoint($l){
			width: calc(82% - 200px);
			height: 100vh;
			display: flex;
			align-items: center;
			background: url(../images/bgWhite.png) left center no-repeat;
			background-size: cover;
			padding-left: 200px;
			margin-left: 30%;
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			width: 100%;
			background: none;
			padding: 0;
			margin: 0;
		}
		@include breakpoint($xxl){
			width: calc(100% - 200px - 498px);
			margin-left: 498px;
		}
	}
	.main--forgotPassword &,
	.main--login &{
		@include breakpoint($l){
			width: calc(78% - 200px);
			height: 100vh;
			background: url(../images/bgWhite.png) left center no-repeat;
			background-size: cover;
			margin-left: 35%;
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			width: 100%;
			background: none;
			padding: 224px 0 60px;
			margin: 0;
			height: auto;
		}
		@include breakpoint($xl){
			margin-left: 40%;
			width: calc(70% - 200px);
		}
		@include breakpoint($xxl){
			width: calc(62% - 200px);
			margin-left: 38%;
			
		}
	}
	// .main--forgotPassword &{
	// 	@include breakpoint($l){
	// 		margin-top: 126px;
			
	// 	}
	// }
	.openMenu & {
		z-index: 1
	}
}
.content__header{
	padding: 0 10px;
	@include breakpoint($l){
		padding: 0;
	}
	
	.main--confirmation & {
		margin: auto;
		@include breakpoint($l){
			width: 100%;
		}
	}	
	.main--forgotPassword &,
	.main--login & {
		margin: 0 auto 40px;
		@include breakpoint($l){
			position: absolute;
			left: -49%;
			top: 0;
			bottom: auto;
			margin: auto;
			transform: translateY(230px);
		}
		@include breakpoint($xl){
			left: -63%;
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			transform: none;
			position: relative;
			left: 0;
			display: block;
			margin: 0 auto 40px;
		}
		@include breakpoint($xxl){
			left: -49%;
		}
	}
	.main--login & {
		margin: 0 auto 40px;
		@include breakpoint($l){
			position: absolute;
			left: -49%;
			top: 0;
			bottom: auto;
			margin: auto;
			max-width: 50%;
			transform: translateY(110px);
		}
		@include breakpoint($xl){
			left: -63%;
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			transform: none;
			position: relative;
			left: 0;
			display: block;
			margin: 0 auto 40px;
		}
		@include breakpoint($xxl){
			left: -49%;
			transform: translateY(132px);
		}
	}

}
.content__title{
	font-size: 22px;
	line-height: 1.3em;
	@extend %font-light;
	text-align: center;
	strong {
		@extend %font-regular;
		font-weight: 600;
	}
	@include breakpoint($s){
		font-size: 26px;
	}
	@include breakpoint($l){
		text-align: left;
		font-size: 40px;
	}
	@include breakpoint($l,min, portrait){
		text-align: center;
		max-width: 600px;
		margin: auto;
	}
	@include breakpoint($xxxl){
		font-size: 48px;
	}
	.main--confirmation & {
		@include breakpoint($s){
			font-size: 34px;
		}
		@include breakpoint($l){
			font-size: 36px;
		}
		@include breakpoint($xl){
			font-size: 40px;
		}
		@include breakpoint($l,min, portrait){
			text-align: center;
		}
	}
	.main--forgotPassword & {
		color: $textColor;
		width: 100%;
		text-align: center;
		@include breakpoint($l){
			font-size: 34px;
			color: $white;
			text-align: left
		}
		@include breakpoint($l,min, portrait){
			color: $textColor;
			margin-bottom: 60px;
			text-align: center;
		}
		
		@include breakpoint($xl){
			font-size: 40px;
		}
	}
}

.fcBlue{
	color: $blue;
}

.main .scrollbar-thumb{
	background: #81bbe7;
}
.main .scrollbar-track{
	background: #357096;
}