.form__fieldset{
    border:0;
    padding: 0 0 30px;
    position: relative;
    & + & {
        margin-top: 10px;
    }
    & + [type=submit]{
        margin-top: 28px;
    }
    .main--forgotPassword & + [type=submit]{
        margin-top: 18px;
    }
}
.form__input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $borderInput;
    width: 100%;
    font-size: 16px;
    padding: 10px 10px 5px;
    appearance: none;
    border-radius: 0;
    position: relative;
    z-index: 5;
    background-color: transparent;
    &:focus{
        outline: 0;
    }
    .error &{
        border-color: $error;
    }
}

.form__label{
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 1;
    font-size: 16;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    color: $placeholder;
    letter-spacing: -0.1px;
    z-index: 1;
    .hasValue + & {
        transform: translate3d(0, -18px, 0);
        font-size: 12px;
    }
}
.form__error {
    color: $error;
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    font-size: 13px;
    width: 100%; 
    @include breakpoint($xs){
        font-size: 14px;
    }
    .form__fieldset.error > &{
        bottom: 5px;
        top: auto; 
        text-align: left;
    }
    form.error > & {
        display: block;
        position: relative;
        top: 0;
        margin-top: -35px;
        margin-bottom: 26px;
    }
    .main--forgotPassword .error &{
        position: relative;
        top: 15px;
        display: block
    }
    .main--forgotPassword .error> &{
        margin-bottom: 32px;
        text-align: center;
        display: block;
    }
    .main--forgotPassword .form__fieldset.error > &,
    .form__fieldset.error > &{
        text-align: left;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        top: auto;
        display: block;
    }
}
.form__link{
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: $formLink;
    &:hover{
        text-decoration: underline;
    }
    
    .form__fieldset.error &{
        @include breakpoint($xs){
            font-size: 14px;
        }
    }
}

.form__toggleShow {
    width: 22px;
    height: 18px;
    background: url(../images/ico-toggle-view.png) no-repeat;
    background-size: cover;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 10;
    &.visible{
        background: url(../images/ico-visible.png) no-repeat;
        background-size: cover;
        width: 22px;
        height: 18px;
    }
}

.button {
    max-width: 345px;
    border-radius: 48px;
    height: 48px;
    width: 100%;
    color: $button;
    font-size: 16px;
    border: 1px solid $button;
    display: block;
    margin: auto;
    cursor: pointer;
    &:hover{
        background-color: $button;
        color: $white; 
    }
    & + & {
        margin-top: 28px;
        @include breakpoint($l){
            margin-top: 18px;
        }
        @include breakpoint($xl){
            margin-top: 28px;
        }
    }
    &.button--submit {
        background-color: $button;
        border: 0;
        color: $white;
        &:hover{
            background-color: $textColor;
        }
    }
}
.form__wraper{
    position: relative;
}

.form__text{
    @extend %font-light;
    line-height: 1.5em;
    letter-spacing: 0.25px;
    margin-bottom: 30px;
    strong {
        @extend %font-regular;
        font-weight: 600;
    }
    .main--login {
        margin-bottom: 0;
    }
    .form__footer & {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0;
        color: #777f88;
        margin-bottom: 0;
        .main--login & {
            font-size: 16px;
            color: $textColor;
        }
    }
    
    br{
        @include breakpoint($s,max){
            display: none;
        }
    }
    
}
.form__logo{
    margin: auto;
}

.form__header{
    margin: auto;
    display: none;
    @include breakpoint($l){
        top: 0;
        position: relative;
        width: 67px;
        margin: 0 auto 60px;
        display: block;
    }
    @include breakpoint($l,min, portrait){
        display: none;
    }
 
    .openMenu & {
        z-index: 1;
    }
}
.form__footer{
    padding: 28px 0;
    @include breakpoint($l){
        padding: 18px 0;     
    }
    @include breakpoint($xl){
        padding: 28px 0;
    }
    .main--forgotPassword &{
        padding-bottom: 0;
    }
}