.social {
    margin-top: 28px;
    @include breakpoint($l){
        margin-top: 18px;
    }
    @include breakpoint($xl){
        margin-top: 28px;
    }
}
.social__icon{
    width: 48px;
    height: 48px;
    display: inline-block;
    vertical-align: middle;
    
    &.social__icon--fb {
        background: url(../images/social/ico-fb.png) 22px 13px no-repeat;
        background-size: 10px auto;
        .button:hover &{
            background: url(../images/social/ico-fb-active.png) 22px 13px no-repeat;
            background-size: 10px auto;
        }
    }
    
    &.social__icon--google {
        background: url(../images/social/ico-google.png) 22px 14px no-repeat;
        background-size: 16px auto;
        .button:hover &{
            background: url(../images/social/ico-google-active.png) 22px 13px no-repeat;
            background-size: 16px auto;
        }
    }
    
}
