// footer styles
.footer{
    position: relative;
    z-index: 25;
    @include breakpoint($l){
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: 30px 58px;
    }
    @include breakpoint($xxl){
        padding:  30px 200px;
    }
    //ipad pro portrait
    @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        position: relative;
    }
    .main--confirmation &{
        @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            top: -180px;
        }
    }

}
.footer__wrapper{
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
    display: block;
    @include breakpoint($l){
       display: flex;
    }
    
    //ipad pro portrait
    @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        width: 80%;
        position: relative;
        flex-wrap: wrap;
    }

}
.footer__menu{
    width: 100%;
    position: relative;
    margin: auto;

}
.footer__menuItems{
    display: flex;
    list-style: none;
    max-width: 250px;
    padding: 0;
    margin-bottom: 20px;
    margin: auto;
    @include breakpoint($l){
        margin: 0;
    }
     //ipad pro portrait
     @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        margin: auto;
    }
}
.footer__menuItem{
    flex: 1 1 auto;
    padding: 0 8px;
    font-size: 14px;
    line-height: 18px;
    @extend %font-light;
    @include breakpoint($l){
        color: $white;
    }
    & + & {
        border-left: 1px solid $border;
        @include breakpoint($l){
            border-color: $white;
        }
        @include breakpoint($l,min, portrait){
            border-color: $border;
        }
    }
    .main--login &{
        color: $textColor;
        @include breakpoint($l){
            & + .footer__menuItem{
                border-color: $border;
            }
        }
    }
    @include breakpoint($l,min, portrait){
        color: $textColor;

    }

}
.footer__logo{
   
    position: relative;
    margin: 24px auto;
    width: 104px;
    @include breakpoint($l){
        position: absolute;
        right: 0;
        margin: 0;
    }
    //ipad pro portrait
    @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        position: relative;
        margin: 24px auto;
    }

}

