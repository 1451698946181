// forgotpassword styles
.forgotPassword{
    width: 100%;
    padding: 0 14px;
    margin: auto;
    @include breakpoint($s){
        width: 345px;
        padding: 0;
        margin-top: 0;
    }
    @include breakpoint($l){
        position: absolute;
        right: 0;
        top: 0;
        bottom: auto;
        margin: auto;
        transform: translateY(230px);
        margin-top:-15px;
    }
    @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        width: 345px;
        padding: 0 14px;
        margin: auto;
        transform: none;
        position: relative;
    }
}