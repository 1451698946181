// ----- Clearfix ----- //
@mixin clearfix {
    *zoom: 1;
	&:before,
	&:after{
        content: " ";
		display: table;
    }
	&:after{
        clear: both;
    }		
}

@mixin breakpoint($breakpoint, $dir : min, $orientation: null) {
    @if($orientation){
        @media only screen and (#{$dir}-width: $breakpoint) and (orientation : $orientation){
            @content;
        }
    }@else {
        @media only screen and (#{$dir}-width: $breakpoint) {
            @content;
        }
    }
}
