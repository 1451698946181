html {
	height: 100%;
}

body {
	background: $white;
	color: $textColor;
	line-height: 1.5em;
	font-size: $base-fontSize;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
	@extend %font-regular;
	font-weight: 400;
	&.openMenu{
		overflow: hidden;
	}
	@include breakpoint($l){
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	color: inherit;
}
img {
	display: block;
	width: 100%;
}
h1,h2,h3,h4,h5 {
	padding: 0;
	margin: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}
.wrapper {
	max-width: 1440px;
	margin: auto;
	position: relative;
	z-index: 5;
	&:before{
		@include breakpoint($l){
			content: '';
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.5);
			width: 100%;
			height: 100vh;
			opacity: 0;
			z-index: 12;
		}
	
	}
	&:after {
		@include breakpoint($l){
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background-color:$white;
			transform: translateX(65%);
			z-index: -1;
		}
		@media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
			content: none;
		 }
	}
	.openMenu & {
		&:before{
			opacity: 1;
			transition: all .2s ease-in
		}
	}
}

