//color
$white: #ffffff;
$textColor: #112038;
$blue: #3984be;
$border: #112038;
$borderInput:#ced4da;
$placeholder: #ced4da;
$error: #ed5024;
$button: #3984be;
$formLink: #3984be;

//fonts
$base-fontSize: 16px;

//breakpoint
$xs: 340px; 
$s: 480px; 
$m:768px; 
$l: 1024px; 
$xl: 1280px; 
$xxl: 1440px;
$xxxl: 1500px;