// confirmation styles
.confirmation {
    width: 85%;
    margin: 60% auto 0;
    min-height: 150px;
    @include breakpoint($s){
        min-height: 160px;
        margin: 65% auto 0;
        width: 75%;
        min-height: 280px;
    }
    @include breakpoint($m){
        margin: 40% auto 0;
        margin-top: 50%;
    }
    @include breakpoint($l){
        margin-top: 0;
        min-height: 0;
        width: 100%;
    }
}
.confirmation__status {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 40px;
    bottom: 0;
    left: -40px;
    margin: auto;
    display: none;
    @include breakpoint($l){
        display: block;
    }
    @media only screen  and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
       display: none;
    }
    .main--confirmation.success &,
    .main--confirmation.failure &{
        width: 80px;
        height: 80px;
        top: 0;
        left: 0;
    }
}