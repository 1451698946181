// header styles
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    
    @include breakpoint($l){
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }
    @include breakpoint($l,min, portrait){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 30;
    }
    .main--login & {
        background-color: $white;
        @include breakpoint($l){
            background-color: transparent;
        }
    }
 
   
}
.header__logo{
    width: 90px;
    position: relative;
    z-index: 10;
    @include breakpoint($s){
        width: 130px;
    }     
}

.header__logo--center {
    width: 30px;
    height: 44px;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    margin: auto; 
    @include breakpoint($s){
        width: 42px;
    }
    .main--login &{
        display: block;
        @include breakpoint($l){
            display: none
        }
        @include breakpoint($l,min, portrait){
            display: block
        }
    }
}
.header__wrapper{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 14px;
    @include breakpoint($l){
        padding: 0 58px;
    }
    @include breakpoint($xxl){
       width: calc(100% - 400px);
       padding: 0;
    }
}
.header__menu{
    margin: auto;
    padding: 69px 27px 30px;
    transform: translateX(100%);
    width: 100%;
    height: 100vh;
    @include breakpoint($s){
        padding: 90px 30px 30px;
    }
    @include breakpoint($l){
        padding: 90px 60px 0;
    } 
    @include breakpoint($xl){
        padding: 129px 60px 0;
    } 

    .openMenu & {
        transform: translateX(0);
        transition: all .2s ease-in;
        background: rgb(43,63,93);
        background: url(../images/bg-menu-mobile.jpg) center top no-repeat;
        background-size: cover ;
        @include breakpoint($l){
            transform: translateX(80px);
            background: linear-gradient(90deg, rgba(43,63,93,1) 0%, rgba(53,112,150,1) 100%);
        }
        @include breakpoint($xl){
            transform: translateX(25%);
        }
    }
}
.header__menuWrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    overflow: hidden;
    right: 0;
    @include breakpoint($l){
        right: 58px;
        position: absolute;
        height: 85px;
    }
    @include breakpoint($xxl){
        right: 0;
    }
    .openMenu & {
        overflow: visible;
        width: 100%;  
        z-index: 10;
        height: 100vh;
        
        @include breakpoint($l){
            position: fixed;
            width: 50%;  
            right: 80px;
        }
        @include breakpoint($xl){
            width: 40%;  
            right: 10%;
        }
    }
}

.menu__button{
    width: 30px;
    height: 49px;
    display: block;
    background: url(../images/menu-mobile.png) no-repeat;
    background-size: contain;
    margin-top: 14px;
    position: absolute;
    right: 14px;
    top: 5px;
    right: 15px;
    @include breakpoint($s){
        width: 38px;
        top: 19px;
    }
   
    @include breakpoint($l){
        background: url(../images/menu.png) no-repeat;
        background-size: contain;
        width: 38px;
        height: 49px;
        right: 0;
    }
    @include breakpoint($l,min, portrait){
        top: 15px; 
    }
    
    &:hover{
        background: url(../images/menu-active.png) no-repeat;
        background-size: contain;
    }
    .openMenu &{
        right: 14px;
        @include breakpoint($s){
            right: 10%;
        }
        @include breakpoint($l){
            right: 0;
        }
        @include breakpoint($xxxl){
            right: 6.25%;
        }
    }
}

.close {
    display: block;
    width: 21px;
    height: 21px;
    background: url(../images/close.png) no-repeat;
    background-size: contain;
    position: absolute;
    top: 16px;
    right: 30px;
    opacity: 0;
    @include breakpoint($m){
        top: 30px;
        width: 30px;
        height: 30px;
    }
    @include breakpoint($l){
        right: 60px;
    }
    .openMenu & {
        opacity: 1;
        transition: all .4s ease-in-out .3s;
    }

}

.menu {
    overflow-y: auto;
    padding-bottom: 60px;
    height: calc(100vh - 149px);
    @include breakpoint($l){
        height: calc(100vh - 230px);
    }
}
.menu_items{
    list-style: none;
    padding: 0;
    padding-bottom: 80px;
    padding-right: 20px;
}

.menu__header{
   opacity: 0;
    .openMenu & {
        transform: translateY(0);
        opacity: 1;
        transition: all .4s ease-in-out .3s;
    }
}

.menu__title{
    @extend %font-bold;
    color: $white;
    font-size: 26px;
    line-height: 1em;
    @include breakpoint($l){
        font-size: 40px;
    }
    & + * {
        margin-top: 22px;
    }
}
.menu__text{
    color: $white;
    padding: 0;
    padding-bottom: 10px;
    font-size: 16px;
    @include breakpoint($m){
        padding-bottom: 40px;
    }
}
.menu__item{
    font-size: 22px;
    line-height: 1.2em;
    padding: 27px 0;
    color: $white;
    border-bottom: 1px solid $white;
    opacity: 0;
    display: block;
    @include breakpoint($l){
        font-size: 26px;
    }
    .openMenu & {
        opacity: 1;
        transition: all .4s ease-in-out .5s;
    }
}