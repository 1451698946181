
@font-face {
    font-family: LinotypeUniversW01-Light;
    src: url('../fonts/LinotypeUniversW01-Light.woff') format("woff")
}

@font-face {
    font-family: LinotypeUniversW01-Regular;
    src: url('../fonts/LinotypeUniversW01-Regular.woff')format("woff")
}

@font-face {
    font-family: LinotypeUniversW01-Bold;
    src: url('../fonts/LinotypeUniversW01-Bold.woff') format("woff")
}

%font-light {
    font-family: LinotypeUniversW01-Light,sans-serif;
    font-weight: 300;
}

%font-regular {
    font-family: LinotypeUniversW01-Regular,sans-serif;
}

%font-bold {
    font-family: LinotypeUniversW01-Bold, sans-serif;
    font-weight: 600;
}